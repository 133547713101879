import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import styles from './Offer.module.scss';
import { Link } from 'react-router-dom';

export default function Offer(props) {
  const [visible, setVisible] = useState(true)

  const closeHandle = (event) => {
    event.preventDefault()
    setVisible(false)
  }

  if(!visible) return;

  return (
    <Link to={props.to}>
      <div className={styles.item} key={props.title}>
        <div onClick={closeHandle} className={styles.close}>
          <FontAwesomeIcon icon={faXmark}/>
        </div>
        <div>
          <FontAwesomeIcon icon={props.icon}/>
        </div>
        <div className={styles.title}>
          {props.text}
        </div>
      </div>
    </Link>
  )
}
