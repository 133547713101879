import { createSlice } from "@reduxjs/toolkit";
import { auth, logout, checkToken } from "../actions/AuthActions";

const initialState = {
  user: {},
  isAuth: false,
  isPending: null,
  error: ''
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) =>  {
    builder
      .addCase(auth.pending, (state, action) => {
        state.isPending = true
      })
      .addCase(auth.fulfilled, (state, action) => {
        state.isPending = false;
        state.user = action.payload.user
        state.isAuth = true
        state.error = '';
      })
      .addCase(auth.rejected, (state, action) => {
        state.isPending = false;
        state.error = action.payload.error
        state.isAuth = false
        state.user = {};
      })
      .addCase(checkToken.pending, (state, action) => {
        state.isPending = true
      })
      .addCase(checkToken.fulfilled, (state, action) => {
        state.user = action.payload.user
        state.isPending = false
        state.isAuth = true
      })
      .addCase(checkToken.rejected, (state, action) => {
        state.isAuth = false
        state.error = action.payload.error
        state.isPending = false
        state.user = {}
      })
      .addCase(logout.pending, (state, action) => {
        state.isPending = true
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.isPending = false
        state.isAuth = false
        state.user = {}
      })
      .addCase(logout.rejected, (state, action) => {
        state.error = action.payload.error
        state.isPending = false
      })
  }
})

export default authSlice.reducer