import React from "react";
import styles from "./Logo.module.scss"
import logo from "../../../../assets/img/logo.png"
import { useNavigate } from "react-router";

export default function Logo() {
  let navigate = useNavigate();

  const onClickLogo = () => {
    navigate("/")
  }

  return (
    <div className={styles.logo_wrapper}>
      <div className={styles.logo} onClick={onClickLogo}>
        <img src={logo} alt="Logo"></img>
        <div className={styles.version}>
          v{process.env.REACT_APP_APP_VERSION}
        </div>
      </div>
    </div>
  );
}
<div className={styles.logo}>
  <img src={logo} alt="Logo"></img>
</div>