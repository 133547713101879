import React from 'react'
import styles from './AdsCampaignsFeed.module.scss'
import AdsCampaignsFeedCard from './AdsCampaignsFeedCard'

export default function AdsCampaignsFeed() {
  return (
    <div className={styles.campaignsBlock}>
      <div className={styles.header}>
        <h3 className={styles.headerTitle}>
          Рекламные кампании
        </h3>
        <div className={styles.right}>
          <div className={styles.links}>
            Все рекламные кампании →
          </div>
        </div>
      </div>
      <div className={styles.feed}>
        <AdsCampaignsFeedCard status={'Идут показы'}/>
        <AdsCampaignsFeedCard status={'Одобрено'} statusColor={'#254C7D'}/>
        <AdsCampaignsFeedCard status={'Одобрено'} statusColor={'#254C7D'}/>
        <AdsCampaignsFeedCard status={'Одобрено'} statusColor={'#254C7D'}/>
        <AdsCampaignsFeedCard status={'Одобрено'} statusColor={'#254C7D'}/>
      </div>
    </div>
  )
}
