import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe, faSearch } from '@manysale/pro-light-svg-icons'
import React from 'react'
import { useState } from 'react'
import ReactTooltip from 'react-tooltip'
import Content from '../../layout/page/Content'
import styles from './RPMStats.module.scss'


export default function RPMStatsMenu({keywords, isUniqueKeywords, toggleUniqueKeywords}) {
  const countKeywords = {
    all: keywords.reduce((acc, value) => acc + value.keywords.length, 0),
    unique: keywords.length
  }

  return (
    <Content style={{ gap: 0 }}>
        <div className={styles.menu}>
          <div className={`${styles.keywords} ${styles.active}`}>
            <span>Ключевые слова</span>
            <div className={styles.count}>{countKeywords.all} / {countKeywords.unique}</div>
          </div>
          <div className={styles.categories}>
            <span>Рубрики</span>
            <div className={styles.count}>6</div>
          </div>
          <div className={styles.region}>
            <FontAwesomeIcon className={styles.globeIcon} icon={faGlobe} />
            <select>
              <option value="1">Москва и МО</option>
              <option value="2">Краснодар</option>
              <option value="3">Хабаровск</option>
              <option value="4">Казань</option>
            </select>
          </div>
          <div className={styles.search}>
            <input type="text" placeholder="Поиск ключевого слова" />
            <FontAwesomeIcon className={styles.searchIcon} icon={faSearch} />
          </div>
          <div className={styles.unique}>
            <button onClick={toggleUniqueKeywords} className={isUniqueKeywords ? styles.active : 'null'}>
              {isUniqueKeywords ? (
                <>Уникальные запросы - {countKeywords.unique}</>
              ) : (
                <>Абсолютные запросы - {countKeywords.all}</>
              )}
              <div
                className={styles.hint}
                data-tip
                data-for="uniqueKeysTip"
              ></div>
              <ReactTooltip
                className={styles.uniqueKeysTip}
                id="uniqueKeysTip"
                place="left"
                globalEventOff="click"
              >
                Уникальная выдача позволяет отбросить из таблицы лишние ключи,
                которые дублируют одинаковую статистику.
                <span>
                  Пример: <b>Менструальная чаша</b> и <b>Чаши менструальные</b>{" "}
                  - разные ключевые слова, но отображают одинаковую выдачу на
                  сайте Wildberries.
                </span>
              </ReactTooltip>
            </button>
          </div>
        </div>
      </Content>
  )
}
