import React, { useEffect, useState } from 'react'
import { InfinitySpin } from 'react-loader-spinner'
import { Link } from 'react-router-dom'
import PasswordStrengthBar from 'react-password-strength-bar'
import styles from './RegisterPage.module.scss'
import decorations from '../../../assets/styles/decorations.module.scss'
import logo from '../../../assets/img/logo.png'
import Version from '../../layout/version/Version'
import { useSelector, useDispatch } from 'react-redux'
import { register } from '../../../store/actions/AuthActions'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router'

export default function RegisterPage() {
  const dispatch = useDispatch();
  const {isPending, registered, error} = useSelector(state => state.registerReducer)
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [isValidLogin, setIsValidLogin] = useState(false)
  const [isValidEmail, setIsValidEmail] = useState(false)
  const [passwordScore, setPasswordScore] = useState()
  const [isReady, setIsReady] = useState(false)
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault()

    const actionResult = dispatch(register({login, email, password}))
    actionResult.then((action) => {
      if(action.payload?.error === 'users_login_key'){
        return toast.error('Пользователь с таким логином уже существует');
      }
      if(action.payload?.error === 'users_email_key'){
        return toast.error('Пользователь с такой почтой уже существует');
      }
      return toast.error(action.payload?.error);
    })
  }

  useEffect(() => {
    if(registered){
      localStorage.setItem('needActivate', true)
      toast.success('Регистрация прошла успешно. Авторизуйтесь с вашими данными.', {
        autoClose: 3000,
        onClose: () => {
          navigate('/login')
        }
      })
    }
  }, [registered, navigate])



  useEffect(() => {
    setIsValidEmail(
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    )
  }, [email])

  useEffect(() => {
    setIsValidLogin(
      login && login.length >= 4
    )
  }, [login])

  useEffect(() => {
    if(login && password && isValidEmail && passwordScore >= 3){
      return setIsReady(true)
    }
    return setIsReady(false)
  }, [login, password, isValidEmail, passwordScore, isReady])


  return (
    <div className={styles.page}>
      <div className={styles.logo}>
        <img src={logo} alt="Logo"></img>
      </div>
      <div className={decorations.circleTop}/>
      <form
        className={isPending ? `${styles.form} ${styles.formPending}` : isReady ? `${styles.form} ${styles.formReady}` : styles.form}
        onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder='Логин'
          className={ login ? isValidLogin ? styles.valid : null : null }
          value={login}
          onChange={(e) => setLogin(e.target.value)}
          disabled={isPending}
        />
        {
          login && !isPending &&
          <div className={styles.passwordText}>
            Логин должен быть <b>от 4 символов</b>, состоящий только из <b>букв</b> и <b>цифр</b>
          </div>
        }
        <input
        type="email"
        className={ email ? isValidEmail ? styles.valid : null : null }
        placeholder='E-mail'
        value={email}
        disabled={isPending}
        onChange={(e) => setEmail(e.target.value)}
        />
        <input
        type="password"
        placeholder='Пароль'
        className={ password ? passwordScore >= 3 ? styles.valid : null : null }
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        disabled={isPending}
        />
        {
          password && !isPending &&
          <div className={styles.passwordText}>
            Рекомендуеи использовать пароль, который состоит из <b>8 символов</b> с использованием <b>заглавных букв</b>, <b>спец-символов</b> и <b>цифр</b> 
          </div>
        }
        {
          password && !isPending &&
          <PasswordStrengthBar
            className={styles.PasswordStrengthBar}
            password={password}
            scoreWordClassName={styles.scoreWord}
            shortScoreWord={'слишком короткий'}
            scoreWords={['слабый','слабый','обычный','хороший','сильный']}
            barColors={['#ddd', '#ef4836', '#f6b44d', '#3265A6', '#14e442']}
            onChangeScore={(score => setPasswordScore(score))}
          />
        }
        <button
          type="submit"
          className={isPending ? styles.pending : null}
          value={'Создать аккаунт'}
          disabled={!isReady || isPending}
        >
          {!isPending && <>Создать аккаунт</>}
          {
            isPending &&
            <div className={styles.loader}>
              <InfinitySpin
                width="100"
                color="#254C7D"
              />
            </div>
          }
        </button>
        <div className={styles.regText}>
          Уже зарегистрированы?
          <Link to="/login">
            Войдите
          </Link>
        </div>
        <div className={styles.policy}>Отправляя ваши данные для регистрации аккаунта, вы автоматически соглашаетесь с нашей <a href="/">Политикой конфиденциальности</a></div>

      </form>
      <Version/>
    </div>
  )
}
