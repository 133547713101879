import React from 'react';
import styles from './Statsfield.module.scss';

export default function Statsfield() {
  return (
    <div className={styles.statsfield}>
      <div className={styles.field}>
        <div className={styles.title}>
          <span>Товары на складе</span>
          <div className={styles.right}>
            <a href="/" className={styles.selected}>
              FBO
            </a>
            /
            <a href="/">
              FBS
            </a>
          </div>
        </div>

        <div className={styles.numbers}>
          <div className={styles.dot}></div>
          <div className={styles.number}>12 412 <span>шт.</span></div>
        </div>

        <div className={styles.numbers}>
          <div className={styles.dot}></div>
          <div className={styles.number}>14 844 320 <span>руб.</span></div>
        </div>
      </div>

      <div className={styles.field}>
        <div className={styles.title}>
          <span>Заказали</span>
          <div className={styles.right}>
            <a href="/" className={styles.selected}>
              неделя
            </a>
            /
            <a href="/">
              месяц
            </a>
          </div>
        </div>

        <div className={styles.numbers}>
          <div className={styles.arrowDown}></div>
          <div className={styles.number}>2 416 <span>шт.</span></div>
        </div>

        <div className={styles.numbers}>
          <div className={styles.arrowUp}></div>
          <div className={styles.number}>3 441 680 <span>руб.</span></div>
        </div>
      </div>

      <div className={styles.field}>
        <div className={styles.title}>
          <span>Выкупили</span>
          <div className={styles.right}>
            <a href="/">
              неделя
            </a>
            /
            <a href="/" className={styles.selected}>
              месяц
            </a>
          </div>
        </div>

        <div className={styles.numbers}>
          <div className={styles.arrowUp}></div>
          <div className={styles.number}>7 992 <span>шт.</span></div>
        </div>

        <div className={styles.numbers}>
          <div className={styles.arrowUp}></div>
          <div className={styles.number}>14 228 900 <span>руб.</span></div>
        </div>
      </div>

      <div className={styles.field} style={{width: 194}}>
        <div className={styles.title}>
          <span>Статус API</span>
          <div className={styles.right}>
            <a href="/">
              Настройки →
            </a>
          </div>
        </div>
        <div className={styles.data}>
          <div className={styles.name}>Поставщик</div>
          <div className={`${styles.badge} ${styles.active}`}>Подключено</div>
        </div>
        <div className={styles.data}>
          <div className={styles.name}>Реклама</div>
          <div className={`${styles.badge} ${styles.inactive}`}>Отключено</div>
        </div>
        <div className={styles.data}>
          <div className={styles.name}>Обновлено</div>
          <div className={styles.badge}>4 мин. назад</div>
        </div>
      </div>
    </div>
  )
}
