import { createAsyncThunk } from '@reduxjs/toolkit'
import RpmService from "../../services/RPMService";

export const getProduct = createAsyncThunk(
  'rpmProduct',
  async (data, thunkAPI) => {
    const {productId} = data
    try {
      const response = await RpmService.getProduct(productId)
      if(response.data?.error){
        return thunkAPI.rejectWithValue(response.data)
      }
      return response.data
    } catch(e) {
      console.log(e.message)
    }
  }
)

export const getKeywords = createAsyncThunk(
  'rpmKeywords',
  async (data, thunkAPI) => {
    const {productId, regionId} = data
    try {
      const response = await RpmService.getKeywords(productId, regionId)
      if(response.data?.error){
        return thunkAPI.rejectWithValue(response.data)
      }
      return response.data
    } catch(e) {
      console.log(e.message)
    }
  }
)

export const getHints = createAsyncThunk(
  'rpmHints',
  async (data, thunkAPI) => {
    const {query, signal} = data
    try {
      const response = await RpmService.getHints(query, signal)
      if(response.data?.error){
        return thunkAPI.rejectWithValue(response.data)
      }
      return response.data
    } catch(e) {
      console.log(e.message)
    }
  }
)

export const getProductsByQuery = createAsyncThunk(
  'rpmSearchProducts',
  async (query, thunkAPI) => {
    try {
      const response = await RpmService.getProductsByQuery(query)
      if(response.data?.error){
        return thunkAPI.rejectWithValue(response.data)
      }
      return response.data
    } catch(e) {
      console.log(e)
    }
  }
)

export const clearProducts = createAsyncThunk(
  'rpmClearProducts',
  async () => {console.log('wtf')}
)