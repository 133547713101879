import axios from 'axios';

const $api = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_URL
})

$api.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`
  return config
})

$api.interceptors.response.use((config) => {
  return config
}, async (error) => {
  console.log(error)
  if(error.response.status === 0){
    return { data: { error: 'No connection to Backend API' } }
  }
  if(error.response.status === 401 && localStorage.getItem('refresh_token')){
    try {
      const originalRequest = error.config
      const response = await axios.post('/auth/refresh', {refresh_token: localStorage.getItem('refresh_token')}, {
        withCredentials: true,
        baseURL: process.env.REACT_APP_API_URL
      })
      localStorage.setItem('access_token', response.data.access_token)
      localStorage.setItem('refresh_token', response.data.refresh_token)
      return $api.request(originalRequest)
    } catch(e){
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')
      return error.config
    }
  }
  return error.config
})

export default $api