import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@manysale/pro-light-svg-icons";
import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { LazyLoadImage } from "react-lazy-load-image-component";

import {
  clearProducts,
  getHints,
  getProductsByQuery,
} from "../../../store/actions/RpmActions";
import { productCardPoster } from "../../../utils";
import Content from "../../layout/page/Content";
import styles from "./RPMSearch.module.scss";
import "react-lazy-load-image-component/src/effects/blur.css";
import ReactTooltip from "react-tooltip";
import { useLayoutEffect } from "react";

const ENTER_KEY = "Enter";

const EXAMPLE_KEYWORDS = [
  "платье детское",
  "зарядка для iphone",
  "часы настольные",
  "беспроводные наушники",
  "менструальные чаши",
];

export default function RPMSearch() {
  let { query } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const abortHintsSignal = useRef(null);
  const searchInputRef = useRef(null);
  const [exampleKeyword, setExampleKeyword] = useState(EXAMPLE_KEYWORDS[0]);
  const [searchQuery, setSearchQuery] = useState(query || "");
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isQueryWasTyped, setIsQueryWasTyped] = useState(false);
  const [isHintSelected, setIsHintSelected] = useState(false);

  const {
    hints,
    isPending: isHintsPending,
    error: hintsError,
  } = useSelector((state) => state.rpmHintsReducer);

  const {
    products,
    keywordStats,
    isPending: isProductsPending,
    error: productsError,
  } = useSelector((state) => state.rpmSearchProductsReducer);

  const suggestions = hints?.filter((hint) => hint.type === "suggest") || [];
  const foundSuggestions = suggestions?.length > 0 && searchQuery;

  const onFocus = () => {
    setIsInputFocused(true);
    setIsHintSelected(false);
  };
  const onBlur = () => setIsInputFocused(false);

  const onHintClick = (e) => {
    e.preventDefault();
    const hint = e.target.innerText;
    setSearchQuery(hint);
    setIsHintSelected(true);
    navigate(`/rpm/${hint}`);
  };

  const onKeyUpHandler = (event) => {
    setIsHintSelected(false);
    setIsQueryWasTyped(true);
    if (event.key === ENTER_KEY && isInputFocused && searchQuery.length >= 3) {
      searchInputRef.current.blur();
      onBlur();
      setIsHintSelected(true);
      navigate(`/rpm/${searchQuery}`);
    }
  };

  const onExampleKeywordClick = () => {
    setSearchQuery(exampleKeyword);
    navigate(`/rpm/${exampleKeyword}`);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const index = EXAMPLE_KEYWORDS.findIndex((val) => val === exampleKeyword);
      const nextIndex = index === EXAMPLE_KEYWORDS.length - 1 ? 0 : index + 1;
      setExampleKeyword((prev) => EXAMPLE_KEYWORDS[nextIndex]);
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, [exampleKeyword]);

  useLayoutEffect(() => {
    if (!searchQuery || searchQuery === query) return; //|| !isQueryWasTyped || !isInputFocused
    const debounce = setTimeout(() => {
      abortHintsSignal.current = new AbortController();
      dispatch(
        getHints({
          query: searchQuery,
          signal: abortHintsSignal.current.signal,
        })
      );
    }, 200);

    return () => {
      clearTimeout(debounce);
      if (abortHintsSignal.current) {
        abortHintsSignal.current.abort();
      }
    };
  }, [searchQuery, isInputFocused, isQueryWasTyped]);

  useEffect(() => {
    setSearchQuery(query || "");

    if (query === undefined) {
      dispatch(clearProducts(query));
      return;
    }

    dispatch(getProductsByQuery(query));
  }, [dispatch, query]);

  return (
    <>
      <Content>
        <div className={styles.header}>
          <div className={styles.left}>
            <h3 className={styles.headerTitle}>
              <div
                className={styles.backBtn}
                onClick={() => navigate(-1)}
              ></div>
              RPM<span className={styles.grey}>#Поиск</span>
            </h3>
            <div className={styles.search}>
              <input
                ref={searchInputRef}
                onKeyUp={onKeyUpHandler}
                onFocus={onFocus}
                onBlur={onBlur}
                className={
                  foundSuggestions && isInputFocused
                    ? [styles.searchInput, styles.suggested].join(" ")
                    : styles.searchInput
                }
                onChange={(e) => setSearchQuery(e.target.value)}
                value={searchQuery}
                type="text"
                placeholder="Поиск по ключевому слову или артикулу"
              />
              <FontAwesomeIcon className={styles.searchIcon} icon={faSearch} />
              <div
                className={styles.suggestions}
                style={
                  foundSuggestions && !isHintSelected
                    ? { display: "initial" }
                    : { display: "none" }
                }
              >
                <ul>
                  {suggestions.map((suggestion, index) => (
                    <li onClick={(event) => onHintClick(event)} key={index}>
                      {suggestion.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Content>
      {products.length === 0 && !isProductsPending ? (
        <div className={styles.titler}>
          <div className={styles.title}>Поиск товаров по ключевым словам</div>
          <div className={styles.subtitle}>
            Найдите и выберете товар для RPM анализа
          </div>
          <div className={styles.subtitle}>
            <span onClick={onExampleKeywordClick}>{exampleKeyword}</span>
          </div>
        </div>
      ) : null}
      <Content style={{ flexDirection: "column", marginTop: 15, gap: 15 }}>
        {isProductsPending ? (
          "Loading..."
        ) : products.length > 0 ? (
          <>
            <div className={styles.result}>
              Результат поиcка по запросу:&nbsp;<b>{query}</b>&nbsp;(найдено
              товаров&nbsp;<b>{products.length}</b>&nbsp;/&nbsp;
              {keywordStats.totalProducts})
            </div>
            <div className={styles.products}>
              {products.map((product) => (
                <div
                  className={styles.product}
                  onClick={() => navigate(`/rpm/product/${product.ProductId}`)}
                >
                  <LazyLoadImage
                    className={styles.photo}
                    alt="poster"
                    effect="opacity"
                    src={productCardPoster(product.ProductId, "SMALL")}
                  />
                  {product.promoName ? (
                    <div className={styles.promo}>{product.promoName}</div>
                  ) : null}
                  <div className={styles.topLine}>
                    <div className={styles.discountPhrase}>
                      <s>{product.priceRetail},00</s>
                      <span>-{product.priceDiscount}%</span>
                    </div>
                    <div className={styles.leftPhrase}>Остатки</div>
                  </div>
                  <div className={styles.priceLine}>
                    <div className={styles.price}>
                      {product.priceTotal / 100},00
                    </div>
                    <div
                      className={styles.stocksLeft}
                      data-tip
                      data-for={`stocks-${product.ProductId}`}
                    >
                      {product.stocks
                        ? product.stocks.reduce(
                            (acc, stock) => acc + stock.qty,
                            0
                          )
                        : "?"}{" "}
                      <span>шт</span>
                    </div>
                    {product.stocks ? (
                      <ReactTooltip
                        className={styles.stocksHint}
                        id={`stocks-${product.ProductId}`}
                        place="left"
                        globalEventOff="click"
                      >
                        {product.stocks.map((stock) => (
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {stock.wh ? stock.wh : "Склад"} <b>{stock.qty}</b>
                          </p>
                        ))}
                      </ReactTooltip>
                    ) : null}
                  </div>
                  <div className={styles.brand}>{product.brandName}</div>
                  <div className={styles.title}>{product.title}</div>
                </div>
              ))}
            </div>
          </>
        ) : null}
      </Content>
    </>
  );
}
