import { createSlice } from "@reduxjs/toolkit";
import { register } from "../actions/AuthActions.js"

const initialState = {
  isPending: false,
  registered: false,
  error: ''
}

export const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {},
  extraReducers: (builder) =>  {
    builder
      .addCase(register.pending, (state, action) => {
        state.isPending = true
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isPending = true;
        state.registered = action.payload.register
        state.error = '';
      })
      .addCase(register.rejected, (state, action) => {
        state.isPending = false;
        state.registered = false;
        state.error = action.payload.error
        state.user = {};
      })
  }
})

export default registerSlice.reducer