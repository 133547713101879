import React from 'react'
import styles from './Version.module.scss'

export default function Version(props) {
  return (
    <div className={styles.version} style={props.style}>
      build version {process.env.REACT_APP_APP_VERSION}
    </div>
  )
}
