import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from './reducers/AuthSlice'
import registerReducer from './reducers/RegisterSlice'
import rpmKeywordsReducer from './reducers/RpmKeywordsSlice'
import rpmProductReducer from './reducers/RpmProductSlice'
import rpmHintsReducer from './reducers/RpmHintsSlice'
import rpmSearchProductsReducer from './reducers/RpmSearchProductsSlice'

const rootReducer = combineReducers( {
  authReducer,
  registerReducer,
  rpmKeywordsReducer,
  rpmProductReducer,
  rpmHintsReducer,
  rpmSearchProductsReducer,
})

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
  })
}