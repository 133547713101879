import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@manysale/pro-light-svg-icons'
import React, { useState } from 'react'
import styles from './ButtonNotice.module.scss'

export default function ButtonNotice() {
  const [notificationsCount] = useState(1);

  let isActive = notificationsCount > 0;

  const classNameHandle = () => {
    const classes = [styles.button_notice, styles.active]
    return isActive ? classes.join(' ') : classes[0];
  }

  const limitedNotificationsCount = () => {
    return notificationsCount > 9 ? '🏆' : notificationsCount;
  }

  return (
    <div className={classNameHandle()} data-count={limitedNotificationsCount()}>
      <FontAwesomeIcon icon={faBell} fade={isActive}/>
    </div>
  )
}
