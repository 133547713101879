const hexToRgbA = (hex, alpha) => {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(
      ","
    )},${alpha})`;
  }
  throw new Error(`Bad Hex ${hex}`);
};

export const RPMWideGraphOptions = (colors = []) => {
  return {
    options: {
      theme: { mode: "dark" },
      colors: colors,
      fill: {
        type: "solid",
        colors: ["transparent"],
      },
      chart: {
        background: "#10100f",
        height: "100%",
        width: "100%",
        type: "line",
        animations: {
          enabled: false,
          easing: "easeinout",
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        parentHeightOffset: 0,
        sparkline: {
          enabled: false,
        },
        dropShadow: {
          enabled: true,
          enabledOnSeries: [0, 1],
          blur: 20,
          opacity: 0.2,
          color: colors.map((color) => hexToRgbA(color, 0.12)),
        },
      },
      dataLabels: {
        enabled: true,
        background: {
          enabled: true,
          padding: 8,
          borderRadius: 4,
        },
        style: {
          fontSize: "12px",
          fontFamily: "Montserrat, Arial, sans-serif",
          fontWeight: 700,
        },
      },
      legend: {
        show: true,
      },
      yaxis: {
        show: true,
        opposite: true,
        reversed: true,
        seriesName: "Позиция",
        forceNiceScale: false,
        min: 1,
        // max: 1200,
        axisBorder: {
          show: false,
        },
        labels: {
          show: true,
          align: "left",
          offsetX: -5,
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat, Arial, sans-serif",
            fontWeight: 600,
          },
        },
        axisTicks: {
          show: false,
        },
      },
      xaxis: {
        type: "datetime",
        position: "top",
        // tickPlacement: "on",
        axisBorder: {
          show: false,
        },
        labels: {
          show: true,
          datetimeFormatter: {
            year: "",
            month: "",
            day: "dd.MM",
            hour: "",
          },
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat, Arial, sans-serif",
            fontWeight: 600,
          },
        },
        axisTicks: {
          show: false,
        },

        crosshairs: {
          show: true,
          width: 1,
          position: "back",
          opacity: 0.9,
          stroke: {
            color: "#b6b6b6",
            width: 2,
            dashArray: 0,
          },
          fill: {
            type: "solid",
            color: "#B1B9C4",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
          dropShadow: {
            enabled: true,
            top: 0,
            left: 0,
            blur: 4,
            opacity: 0.4,
          },
        },
      },
      grid: {
        borderColor: "rgba(129,132,145,.3)",
        strokeDashArray: 4,
        position: "back",
        padding: {
          top: 0,
          right: 20,
          bottom: 0,
          left: 20,
        },
        yaxis: {
          lines: {
            show: true,
            width: 1,
          },
        },
      },
      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "round",
        width: 1,
        dashArray: 4,
      },
      markers: {
        size: 0,
        strokeWidth: 1,
        strokeOpacity: 0.5,
        hover: {
          size: 5,
        },
      },
      tooltip: {
        enabled: true,
        shared: true,
        followCursor: true,
        theme: "dark",
        style: {
          fontSize: "12px",
          fontFamily: "Montserrat, Arial, sans-serif",
          fontWeight: 600,
        },
        onDatasetHover: {
          highlightDataSeries: false,
        },
        x: {
          show: true,
          format: "dd.MM.yyyy",
        },
        y: {
          // formatter: undefined,
          // title: {
          //   formatter: (seriesName) => seriesName,
          // },
        },
        z: {
          formatter: undefined,
          title: "Size: ",
        },
        marker: {
          show: true,
        },
        items: {
          display: "flex",
        },
        fixed: {
          enabled: false,
          position: "topRight",
          offsetX: 0,
          offsetY: 0,
        },
      },
    },
  };
};
