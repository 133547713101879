import React from 'react'
import Hr from '../hr/Hr';
import styles from './Folder.module.scss';

export default function Folder(props) {
  return (
    <>
      <div className={styles.folder}>{props.title}</div>
      {props.children}
      {props.hr && <Hr/>}
    </>
  )
}
