import { useEffect } from "react";
import { useMemo } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { getRandomInt } from "../../../utils";
import RPMStatsKeywords from "./RPMStats.keywords";
import RPMStatsMenu from "./RPMStats.menu";
import styles from "./RPMStats.module.scss";

const graphColors = [
  "#c62828",
  "#ff5f52",
  "#8e0000",
  "#ad1457",
  "#e35183",
  "#78002e",
  "#6a1b9a",
  "#9c4dcc",
  "#38006b",
  "#4527a0",
  "#7953d2",
  "#000070",
  "#283593",
  "#5f5fc4",
  "#001064",
  "#1565c0",
  "#5e92f3",
  "#003c8f",
  "#0277bd",
  "#58a5f0",
  "#004c8c",
  "#00838f",
  "#4fb3bf",
  "#005662",
  "#00695c",
  "#439889",
  "#003d33",
  "#2e7d32",
  "#60ad5e",
  "#005005",
  "#558b2f",
  "#85bb5c",
  "#255d00",
  "#9e9d24",
  "#d2ce56",
  "#6c6f00",
  "#f9a825",
  "#ffd95a",
  "#c17900",
  "#ff8f00",
  "#ffc046",
  "#c56000",
  "#ef6c00",
  "#ff9d3f",
  "#b53d00",
  "#d84315",
  "#ff7543",
  "#9f0000",
];

const sort = ({ data, type, order }) => {
  const isAsc = order === "asc";
  switch (type) {
    case "position":
      return data.sort((a, b) =>
        isAsc
          ? a.stats[0].position - b.stats[0].position
          : b.stats[0].position - a.stats[0].position
      );
    case "views":
      return data.sort((a, b) =>
        isAsc
          ? a.keywords[0].wb_views - b.keywords[0].wb_views
          : b.keywords[0].wb_views - a.keywords[0].wb_views
      );
    case "keyword":
      return data.sort((a, b) =>
        isAsc
          ? a.keywords[0].keyword.localeCompare(b.keywords[0].keyword)
          : -1 * a.keywords[0].keyword.localeCompare(b.keywords[0].keyword)
      );
    case "diff":
      return data.sort((a, b) => {
        if (isAsc) {
          return (
            a.stats[1]?.position -
            a.stats[0]?.position -
            (b.stats[1]?.position - b.stats[0]?.position)
          );
        }
        return (
          b.stats[1]?.position -
          b.stats[0]?.position -
          (a.stats[1]?.position - a.stats[0]?.position)
        );
      });
    default:
      return data.sort((a, b) =>
        isAsc ? a[type] - b[type] : b[type] - a[type]
      );
  }
};

export default function RPMStats({ keywords, isPending, handleGraphData }) {
  const [isUniqueKeywords, setIsUniqueKeywords] = useState(true);
  const [data, setData] = useState([]);
  const [sortedBy, setSortedBy] = useState({
    type: "views",
    order: "desc",
  });

  const sortedKeywords = [...keywords].map((keyword) => {
    const keywordsCopy = [...keyword.keywords];
    keywordsCopy.sort((a, b) => b.wb_views - a.wb_views);
    return { ...keyword, keywords: keywordsCopy };
  });

  const filteredData =
    keywords.length > 0
      ? sort({
          data: data,
          type: sortedBy.type,
          order: sortedBy.order,
        })
      : [];

  const maxViews = filteredData.reduce((max, element) => {
    let sumViews = 0;
    for (let key of element.keywords) {
      sumViews += key.wb_views;
    }
    return max > sumViews ? max : sumViews;
  }, 0);

  const toggleUniqueKeywords = () => {
    setIsUniqueKeywords((prev) => !prev);
  };

  useEffect(() => {
    setData(
      sortedKeywords.map((keyData, index) => {
        let checked = false;
        let color = false;
        return { ...keyData, checked, color };
      })
    );
  }, [keywords]);

  useEffect(() => {
    handleGraphData(data.filter((value) => value.checked));
  }, [data]);

  const toggleCheckbox = (e, index) => {
    data[index].checked = !data[index].checked;
    if (data[index].checked) {
      data[index].color = graphColors[getRandomInt(0, graphColors.length)];
    } else {
      data[index].color = false;
    }
    setData([...data]);
  };


  const onTitleClick = useCallback((type) => {
    let order = "asc";
    if (type === sortedBy.type) {
      order = sortedBy.order === "asc" ? "desc" : "asc";
    }
    setSortedBy({ type, order });
  }, [sortedBy]);

  const setOrderStyle = (type) => {
    if (sortedBy.type === type)
      return sortedBy.order === "asc" ? styles.orderAsc : styles.orderDesc;
  };

  return (
    <>
      <RPMStatsMenu
        keywords={keywords}
        toggleUniqueKeywords={toggleUniqueKeywords}
        isUniqueKeywords={isUniqueKeywords}
      />
      <RPMStatsKeywords
        filteredData={filteredData}
        toggleCheckbox={toggleCheckbox}
        onTitleClick={onTitleClick}
        setOrderStyle={setOrderStyle}
        isPending={isPending}
        isUniqueKeywords={isUniqueKeywords}
        maxViews={maxViews}
      />
    </>
  );
}
