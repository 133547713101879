import { createSlice } from "@reduxjs/toolkit";
import { getProduct } from "../actions/RpmActions";

const initialState = {
  data: {},
  isPending: false,
  error: '',
}

export const RpmProductSlice = createSlice({
  name: 'rpmProduct',
  initialState,
  reducers: {},
  extraReducers: (builder) =>  {
    builder
      .addCase(getProduct.pending, (state) => {
        state.isPending = true
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.isPending = false
        state.data = action.payload
      })
      .addCase(getProduct.rejected, (state, action) => {
        state.isPending = false;
        state.error = action.payload.error
        state.data = {}
      })
  }
})

export default RpmProductSlice.reducer