import $api from "../api/index.js";

export default class AuthService {
  static async auth(login, password) {
    return $api.post('/auth/login', {login, password})
  }

  static async register(login, email, password) {
    return $api.post('/auth/register', {login, email, password})
  }

  static async checkToken() {
    return $api.post('/auth/checkToken')
  }

  static async logout() {
    return $api.post('/auth/logout')
  }
}