import { useSelector } from 'react-redux'
import { Outlet, Navigate } from 'react-router';

export default function AuthRoute({children, not}) {
  const  { isAuth, isPending } = useSelector(state => state.authReducer);


  const hasToken = !!localStorage.getItem('access_token')
  if(hasToken) {
    if(isPending !== false) return null
    if(not) return isAuth ? <Navigate to="/" /> : children;
    return isAuth ? children : <Navigate to="/login" />;
  }
  if(not) return children
  return <Navigate to="/login" />
}