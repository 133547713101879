import React from 'react'
import styles from './PageInner.module.scss'

export default function PageInner(props) {
  return (
    <div className={styles.inner}>
      {props.children}
    </div>
  )
}
