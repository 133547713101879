import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from "react-router-dom";
import { faExternalLink } from "@manysale/pro-light-svg-icons";
import React from "react";
import Content from "../components/layout/page/Content";
import styles from "../components/special/rpm-page/RPM.module.scss";
import RPMStats from "../components/special/rpm-page/RPMStats";
import RPMGraph from "../components/special/rpm-page/RPMGraph";
import ReactTooltip from "react-tooltip";
import { useEffect } from "react";
import {
  getRandomInt,
  productCardPoster,
  randomArrayGenerator,
} from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { getKeywords, getProduct } from "../store/actions/RpmActions";
import ContentLoader from "react-content-loader";
import { useState } from "react";
import { toast } from "react-toastify";
import RPMWideGraph from "../components/special/rpm-page/RPMWideGraph";

export default function RPM() {
  const dispatch = useDispatch();
  const [graphData, setGraphData] = useState([]);
  const {
    keywords,
    isPending: isKeywordsPending,
    error: keywordsError,
  } = useSelector((state) => state.rpmKeywordsReducer);
  const {
    data: productData,
    isPending: isProductPending,
    error: productError,
  } = useSelector((state) => state.rpmProductReducer);

  const handleGraphData = (data) => {
    setGraphData(data);
  };

  const navigate = useNavigate();
  let { productId } = useParams();
  productId = parseInt(productId);

  useEffect(() => {
    if (productError !== "") {
      return toast.error(productError);
    }
  }, [productError]);

  useEffect(() => {
    if (
      !Number.isInteger(productId) ||
      productId < 0 ||
      productId > 2147483647
    ) {
      navigate("/positions");
    }

    dispatch(getKeywords({ productId, regionId: 1 }));
    dispatch(getProduct({ productId }));
  }, []);

  return (
    <>
      <Content>
        <div className={styles.header}>
          <div className={styles.left}>
            <div className={styles.backBtn} onClick={() => navigate(-1)}></div>
            <h3 className={styles.headerTitle}>
              RPM<span className={styles.grey}>#Анализ</span>
            </h3>
          </div>
          <div className={styles.graphTitle}>
            График позиций по ключевым словам и категориям
          </div>
          <div className={styles.right}>
            <div className={styles.categoryBox}>
              <div className={styles.articulTitle}>
                {isProductPending ? (
                  <ContentLoader
                    speed={1}
                    width={100}
                    height={15}
                    viewBox="0 0 100 15"
                    backgroundColor="#191919"
                    foregroundColor="#10100f"
                  >
                    <rect x="0" y="0" rx="4" ry="4" width="100" height="15" />
                  </ContentLoader>
                ) : (
                  "Менструальные чаши"
                )}
              </div>
              <div className={styles.articul}>
                <span className={styles.articulText}>Артикул</span>
                {isProductPending ? (
                  <ContentLoader
                    speed={1}
                    width={52}
                    height={15}
                    viewBox="0 0 52 15"
                    backgroundColor="#191919"
                    foregroundColor="#10100f"
                  >
                    <rect x="0" y="0" rx="4" ry="4" width="52" height="15" />
                  </ContentLoader>
                ) : (
                  <a
                    className={styles.articulNumber}
                    target={"_blank"}
                    rel={"noreferrer"}
                    href={`https://www.wildberries.ru/catalog/${productId}/detail.aspx?targetUrl=NEUROMARKET`}
                  >
                    <FontAwesomeIcon
                      icon={faExternalLink}
                      className={styles.externalIcon}
                    />{" "}
                    {productId}
                  </a>
                )}
              </div>
            </div>
            <div className={styles.priceBox}>
              <div className={styles.price}>
                {isProductPending ? (
                  <ContentLoader
                    speed={1}
                    width={120}
                    height={30}
                    viewBox="0 0 120 30"
                    backgroundColor="#191919"
                    foregroundColor="#10100f"
                  >
                    <rect x="0" y="0" rx="4" ry="4" width="120" height="30" />
                  </ContentLoader>
                ) : (
                  productData.priceTotal / 100 + ",00"
                )}
              </div>
              <div className={styles.label}>
                <span>Текущая цена</span>
              </div>
              <div className={styles.discount}>
                {isProductPending ? (
                  <ContentLoader
                    speed={1}
                    width={20}
                    height={15}
                    viewBox="0 0 20 15"
                    backgroundColor="#191919"
                    foregroundColor="#10100f"
                  >
                    <rect x="0" y="0" rx="4" ry="4" width="20" height="15" />
                  </ContentLoader>
                ) : (
                  <span className={styles.discountNumber}>
                    {productData.priceDiscount}
                  </span>
                )}

                <span className={styles.discountText}>текущая скидка</span>
              </div>
            </div>
            <div className={styles.ratingBox}>
              <div className={styles.rating}>
                {isProductPending ? (
                  <ContentLoader
                    speed={1}
                    width={65}
                    height={40}
                    viewBox="0 0 65 40"
                    backgroundColor="#191919"
                    foregroundColor="#10100f"
                  >
                    <rect x="0" y="0" rx="4" ry="4" width="65" height="40" />
                  </ContentLoader>
                ) : (
                  "8,9"
                )}
              </div>
              <div className={styles.label}>
                <span>Рейтинг</span>
                <div
                  className={styles.hint}
                  data-tip
                  data-for="ratingTip"
                ></div>
                <ReactTooltip
                  className={styles.ratingTip}
                  id="ratingTip"
                  place="top"
                  globalEventOff="click"
                >
                  Мы еще не придумали как будем подсчитывать рейтинг товара.
                  <span>Но показывать его уже хочется 😂</span>
                </ReactTooltip>
              </div>
            </div>
          </div>
        </div>
      </Content>
      <Content>
        <div className={styles.photoBox}>
          <div className={styles.photoSale}>Дни Красоты</div>
          <div className={styles.photoBadges}>
            {productData.photoCount && (
              <div className={styles.photoBadge}>
                {productData.photoCount} фото
              </div>
            )}
            {productData.hasVideo && (
              <div className={styles.photoBadge}>Видео</div>
            )}
            {productData.hasPhoto360 && (
              <div className={styles.photoBadge}>360</div>
            )}
          </div>

          {isProductPending ? (
            <ContentLoader
              speed={1}
              width={400}
              height={400}
              viewBox="0 0 300 407"
              backgroundColor="#191919"
              foregroundColor="#10100f"
              className={styles.photo}
            >
              <rect x="0" y="0" rx="4" ry="4" width="300" height="407" />
            </ContentLoader>
          ) : (
            <img
              className={styles.photo}
              alt="poster"
              src={productCardPoster(productId, "MEDIUM")}
            />
          )}
        </div>

        <div className={styles.graphBox}>
          {isProductPending ? (
            <ContentLoader
              speed={1}
              width={400}
              height={400}
              viewBox="0 0 718 407"
              backgroundColor="#191919"
              foregroundColor="#10100f"
              className={styles.photo}
            >
              <rect x="0" y="0" rx="4" ry="4" width="718" height="407" />
            </ContentLoader>
          ) : (
            <RPMGraph graphData={graphData} />
          )}
        </div>
      </Content>
      <Content>
        <div className={styles.graphWideBox}>
          {isProductPending ? (
            <ContentLoader
              speed={1}
              width={400}
              height={400}
              viewBox="0 0 718 407"
              backgroundColor="#191919"
              foregroundColor="#10100f"
              className={styles.photo}
            >
              <rect x="0" y="0" rx="4" ry="4" width="718" height="407" />
            </ContentLoader>
          ) : (
            <RPMWideGraph graphData={graphData} />
          )}
        </div>
      </Content>
      <RPMStats
        keywords={keywords}
        isPending={isKeywordsPending}
        handleGraphData={handleGraphData}
      />
    </>
  );
}
