import $api from "../api/index.js";

export default class RpmService {
  static async getProduct(productId) {
    return $api.get(`/rpm/product/${productId}`)
  }

  static async getKeywords(productId, regionId) {
    return $api.get(`/rpm/keywords/${productId}/region/${regionId}`)
  }

  static async getHints(query, signal) {
    return $api.get(`/rpm/search/hints/${query}`, {signal})
  }

  static async getProductsByQuery(query, signal) {
    return $api.get(`/rpm/search/${query}`)
  }

}