import {
  faAd, faBoxesAlt, faChartLine, faDesktop, faGrinTears, faPaperPlane, faReceipt, faRocketLaunch, faSack, faSearchDollar, faTicketAlt, faUserAlt
} from '@manysale/pro-light-svg-icons';
import React from "react";
import { Outlet } from 'react-router';
import ButtonNotice from '../ui/button-notice/ButtonNotice';
import ButtonAccountTop from './account-top/ButtonAccountTop';
import Container from "./container/Container";
import Header from './header/Header';
import Page from "./page/Page";
import PageInner from './page/PageInner';
import Folder from './sidebar/navigation/folder/Folder';
import Item from "./sidebar/navigation/item/Item";
import Offer from './sidebar/navigation/offer/Offer';
import Sidebar from "./sidebar/Sidebar";
import Statsfield from './statsfield/Statsfield';

export default function Layout(props) {
  return (
    <Page>
      <Sidebar>
          <Folder title={'Меню'} hr={true}>
            <Item to={'/'} title="Рабочий стол" icon={faDesktop}></Item>
            <Item to={'/products'} title="Товары" icon={faBoxesAlt}></Item>
            <Item to={'/ads'} title="Реклама" icon={faAd} disabled={true}></Item>
          </Folder>
          <Folder title={'SEO Анализ'} hr={true}>
            <Item to={'/rpm'} title="Позиции" icon={faChartLine}></Item>
            <Item to={'/keywords'} title="Подбор ключей" icon={faSearchDollar} disabled={true}></Item>
          </Folder>
          <Folder title={'Настройки'}/>
          <Item to={'/profile'} title="Профиль" icon={faUserAlt} disabled={true}></Item>
          <Item to={'/supplier'} title="Поставщик" icon={faGrinTears} disabled={true}></Item>
          <Folder title={'Биллинг'} hr={true}>
            <Offer to={'/offer'} text={'Скидка 100% для новичков!'} icon={faRocketLaunch} disabled={true}></Offer>
            <Item to={'/tarifs'} title="Тариф" icon={faSack} disabled={true}></Item>
            <Item to={'/receipts'} title="История оплат" icon={faReceipt} disabled={true}></Item>
          </Folder>
          <Folder title={'Поддержка'}/>
          <Item to={'/support'} title="Обращения" icon={faTicketAlt} disabled={true}></Item>
          <Item to={'/telegram'} title="Telegram чат" icon={faPaperPlane} disabled={true}></Item>
      </Sidebar>
      <Container>
        <Header>
          <Statsfield/>
          <ButtonNotice/>
          <ButtonAccountTop/>
        </Header>
        <PageInner>
          <Outlet/>
        </PageInner>
      </Container>
    </Page>
  );
}