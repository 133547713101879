import React from "react";
import ContentLoader from "react-content-loader";
import ReactTooltip from "react-tooltip";
import Content from "../../layout/page/Content";
import styles from "./RPMStats.module.scss";

const numberWithSpaces = (x) => {
  if (x === undefined) return "und";
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const selectColor = (num) => {
  if (num <= 10) return statsColors[0];
  if (num <= 50) return statsColors[1];
  if (num <= 100) return statsColors[2];
  if (num <= 200) return statsColors[3];
  if (num <= 300) return statsColors[4];
  if (num <= 400) return statsColors[5];
};

const statsColors = ["#1A6A44", "#0D6A7E", "#1C3451", "#18283A", "#131B23"];

const RenderDifferenceStats = ({ keyword }) => {
  const ARROWS = {
    UP: styles.arrowUp,
    DOWN: styles.arrowDown,
    NULL: styles.arrowNull,
  };

  let arrow = ARROWS.NULL;
  let difference = 0;

  if (keyword.stats.length > 1) {
    const positionBefore = keyword.stats[1].position;
    const positionCurrent = keyword.stats[0].position;

    difference = positionBefore - positionCurrent;
    if (difference > 0) {
      arrow = ARROWS.UP;
    } else if (difference < 0) {
      arrow = ARROWS.DOWN;
      difference = Math.abs(positionBefore - positionCurrent);
    }
  }

  return (
    <td className={styles.difference}>
      <div className={arrow}></div>
      <span>{difference}</span>
    </td>
  );
};

const catalogTypes = {
  preset: "Шаблон",
  brand: "Бренд",
  merger: "Объединяющий",
  subject: "Категория",
};

// const normalize = (value, max) => {
//   return Math.floor(100 / (max / value));
// };

const normalize = (
  enteredValue,
  maxEntry,
  minEntry = 50,
  normalizedMin = 0,
  normalizedMax = 100
) => {
  var mx = Math.log(enteredValue - minEntry) / Math.log(maxEntry - minEntry);
  var preshiftNormalized = mx * (normalizedMax - normalizedMin);
  var shiftedNormalized = preshiftNormalized + normalizedMin;

  return shiftedNormalized;
};

export default function RPMStatsKeywords({
  filteredData,
  toggleCheckbox,
  onTitleClick,
  setOrderStyle,
  isPending,
  isUniqueKeywords,
  maxViews,
}) {
  return (
    <Content className={styles.statsContent} style={{ gap: 0, marginTop: 10 }}>
      <div className={styles.table}>
        <table>
          <thead>
            <tr>
              <th></th>
              <th
                onClick={() => onTitleClick("keyword")}
                className={styles.keyword}
              >
                <div
                  className={`${styles.orderWrap} ${setOrderStyle("keyword")}`}
                >
                  Поисковый запрос
                </div>
              </th>
              <th
                onClick={() => onTitleClick("position")}
                className={styles.position}
              >
                <div
                  className={`${styles.orderWrap} ${setOrderStyle("position")}`}
                >
                  Позиция
                </div>
              </th>
              <th
                onClick={() => onTitleClick("diff")}
                className={styles.difference}
              >
                <div
                  className={`${styles.orderWrapLeft} ${setOrderStyle("diff")}`}
                >
                  Разница
                </div>
                <div className={styles.hint} data-tip data-for="diffTip"></div>
                <ReactTooltip
                  className={styles.diffTip}
                  id="diffTip"
                  place="top"
                  globalEventOff="click"
                >
                  Данные столбец показывает разницу позиции карточки в выдаче
                  Wildberries по данному ключевому по отношению к последним
                  данным за прошлый день
                </ReactTooltip>
              </th>
              <th className={styles.queryType}>Тип запроса</th>
              <th
                onClick={() => onTitleClick("views")}
                className={styles.views}
              >
                <div
                  className={`${styles.orderWrapLeft} ${setOrderStyle(
                    "views"
                  )}`}
                >
                  Показы за месяц
                </div>
                <div className={styles.hint} data-tip data-for="viewsTip"></div>
                <ReactTooltip
                  className={styles.viewsTip}
                  id="viewsTip"
                  place="left"
                  globalEventOff="click"
                >
                  Данные по количеству просмотров по ключевым словам берутся из
                  официальных данных от Wildberries.
                  <span>
                    Мы <i>не особо доверяем</i> данным от <b>Wildberries</b>,
                    поэтому думаем о подключении дополнительных источников в
                    виде <b>Ozon</b> и <b>Яндекс.Wordstat</b>
                  </span>
                  <span>
                    Данные о показах по ключевым словам обновляются ежемесячно
                    💨
                  </span>
                </ReactTooltip>
              </th>
              <th className={styles.queryType} style={{ paddingLeft: 0 }}>
                Объем трафика
              </th>
            </tr>
          </thead>
          <tbody>
            {isUniqueKeywords
              ? filteredData.map((row, idx) => (
                  <tr key={idx}>
                    <td>
                      <label
                        color={row.color.toString()}
                        style={{ "--keyBoxColor": row.color }}
                      >
                        <input
                          type="checkbox"
                          key={idx}
                          checked={row.checked}
                          onChange={(e) => toggleCheckbox(e, idx)}
                        />
                      </label>
                    </td>
                    <td key={idx} className={styles.keyword}>
                      <a
                        href={`https://www.wildberries.ru/catalog/0/search.aspx?sort=popular&search=${row.keywords[0].keyword}`}
                        rel="noreferrer"
                        target="_blank"
                        className={styles.external}
                      >
                        WB
                      </a>
                      <div
                        className={styles.text}
                        onClick={(e) => toggleCheckbox(e, idx)}
                      >
                        {row.keywords[0].keyword}
                      </div>
                      {/* <div className={styles.sup}>
                  <a href={`https://www.wildberries.ru/catalog/0/search.aspx?sort=popular&search=${row.keyword}`} rel="noreferrer" target="_blank" className={styles.external}>
                    WB <FontAwesomeIcon icon={faExternalLink}/>
                  </a>
                  {row.products.diff > 0 && <div className={styles.diffUp}>{ row.products.diff }</div>}
                  {row.products.diff < 0 && <div className={styles.diffDown}>{ Math.abs(row.products.diff) }</div> }
                  <div className={styles.arrowToTotal}>→</div>
                  <div className={styles.total}>{ row.products.total }</div>
                </div> */}
                    </td>
                    <td className={styles.position}>{row.stats[0].position}</td>
                    <RenderDifferenceStats keyword={row} />
                    <td>
                      <div
                        className={[
                          styles.badge,
                          styles["badge" + row.catalogType],
                        ].join(" ")}
                      >
                        {catalogTypes[row.catalogType]}
                      </div>
                    </td>
                    <td className={styles.views}>
                      {numberWithSpaces(row.totalViews)}
                    </td>
                    <td>
                      <div className={styles.progress}>
                        <div
                          className={styles.bar}
                          style={{
                            width: `${normalize(
                              row.keywords[0].wb_views,
                              maxViews
                            )}%`,
                          }}
                        ></div>
                      </div>
                    </td>
                  </tr>
                ))
              : filteredData.map((row, idx) =>
                  row.keywords.map((key, keyIndex) => (
                    <tr key={`${idx}-${keyIndex}`}>
                      <td>
                        <label
                          color={row.color.toString()}
                          style={{ "--keyBoxColor": row.color }}
                        >
                          <input
                            type="checkbox"
                            key={idx}
                            checked={row.checked}
                            onChange={(e) => toggleCheckbox(e, idx)}
                          />
                        </label>
                      </td>
                      <td key={idx} className={styles.keyword}>
                        <a
                          href={`https://www.wildberries.ru/catalog/0/search.aspx?sort=popular&search=${row.keywords[keyIndex].keyword}`}
                          rel="noreferrer"
                          target="_blank"
                          className={styles.external}
                        >
                          WB
                        </a>
                        <div
                          className={styles.text}
                          onClick={(e) => toggleCheckbox(e, idx)}
                        >
                          {row.keywords[keyIndex].keyword}
                        </div>
                        {/* <div className={styles.sup}>
                    <a href={`https://www.wildberries.ru/catalog/0/search.aspx?sort=popular&search=${row.keyword}`} rel="noreferrer" target="_blank" className={styles.external}>
                      WB <FontAwesomeIcon icon={faExternalLink}/>
                    </a>
                    {row.products.diff > 0 && <div className={styles.diffUp}>{ row.products.diff }</div>}
                    {row.products.diff < 0 && <div className={styles.diffDown}>{ Math.abs(row.products.diff) }</div> }
                    <div className={styles.arrowToTotal}>→</div>
                    <div className={styles.total}>{ row.products.total }</div>
                  </div> */}
                      </td>
                      <td className={styles.position}>
                        {row.stats[0].position}
                      </td>
                      <RenderDifferenceStats keyword={row} />
                      <td>
                        <div
                          className={[
                            styles.badge,
                            styles["badge" + row.catalogType],
                          ].join(" ")}
                        >
                          {catalogTypes[row.catalogType]}
                        </div>
                      </td>
                      <td className={styles.views}>
                        {numberWithSpaces(row.keywords[keyIndex].wb_views)}
                      </td>
                      <td>
                        <div className={styles.progress}>
                          <div
                            className={styles.bar}
                            style={{
                              width: `${normalize(
                                row.keywords[keyIndex].wb_views,
                                maxViews
                              )}%`,
                            }}
                          ></div>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
          </tbody>
        </table>
      </div>
      {isPending && (
        <ContentLoader
          speed={1}
          width={400}
          height={400}
          viewBox="10 130 1618 600"
          backgroundColor="#191919"
          foregroundColor="#10100f"
          className={styles.skeleton}
        >
          <rect x="12" y="140" rx="4" ry="4" width="20" height="20" />
          <rect x="40" y="135" rx="10" ry="10" width="320" height="30" />
          <rect x="400" y="135" rx="10" ry="10" width="100" height="30" />
          <rect x="555" y="135" rx="10" ry="10" width="160" height="30" />
          <rect x="740" y="135" rx="10" ry="10" width="120" height="30" />
          <rect x="898" y="135" rx="10" ry="10" width="40" height="30" />
          <rect x="966" y="135" rx="10" ry="10" width="40" height="30" />
          <rect x="1035" y="135" rx="10" ry="10" width="40" height="30" />
          <rect x="1103" y="135" rx="10" ry="10" width="40" height="30" />
          <rect x="1171" y="135" rx="10" ry="10" width="40" height="30" />
          <rect x="1242" y="135" rx="10" ry="10" width="40" height="30" />
          <rect x="1310" y="135" rx="10" ry="10" width="40" height="30" />
          <rect x="1379" y="135" rx="10" ry="10" width="40" height="30" />
          <rect x="1448" y="135" rx="10" ry="10" width="40" height="30" />
          <rect x="1516" y="135" rx="10" ry="10" width="40" height="30" />
          <rect x="1586" y="135" rx="10" ry="10" width="25" height="30" />

          <rect x="12" y="190" rx="4" ry="4" width="20" height="20" />
          <rect x="40" y="185" rx="10" ry="10" width="210" height="30" />
          <rect x="410" y="185" rx="10" ry="10" width="90" height="30" />
          <rect x="545" y="185" rx="10" ry="10" width="170" height="30" />
          <rect x="770" y="185" rx="10" ry="10" width="90" height="30" />
          <rect x="898" y="185" rx="10" ry="10" width="40" height="30" />
          <rect x="966" y="185" rx="10" ry="10" width="40" height="30" />
          <rect x="1035" y="185" rx="10" ry="10" width="40" height="30" />
          <rect x="1103" y="185" rx="10" ry="10" width="40" height="30" />
          <rect x="1171" y="185" rx="10" ry="10" width="40" height="30" />
          <rect x="1242" y="185" rx="10" ry="10" width="40" height="30" />
          <rect x="1310" y="185" rx="10" ry="10" width="40" height="30" />
          <rect x="1379" y="185" rx="10" ry="10" width="40" height="30" />
          <rect x="1448" y="185" rx="10" ry="10" width="40" height="30" />
          <rect x="1516" y="185" rx="10" ry="10" width="40" height="30" />
          <rect x="1586" y="185" rx="10" ry="10" width="25" height="30" />

          <rect x="12" y="240" rx="4" ry="4" width="20" height="20" />
          <rect x="40" y="235" rx="10" ry="10" width="280" height="30" />
          <rect x="380" y="235" rx="10" ry="10" width="120" height="30" />
          <rect x="585" y="235" rx="10" ry="10" width="130" height="30" />
          <rect x="750" y="235" rx="10" ry="10" width="110" height="30" />
          <rect x="898" y="235" rx="10" ry="10" width="40" height="30" />
          <rect x="966" y="235" rx="10" ry="10" width="40" height="30" />
          <rect x="1035" y="235" rx="10" ry="10" width="40" height="30" />
          <rect x="1103" y="235" rx="10" ry="10" width="40" height="30" />
          <rect x="1171" y="235" rx="10" ry="10" width="40" height="30" />
          <rect x="1242" y="235" rx="10" ry="10" width="40" height="30" />
          <rect x="1310" y="235" rx="10" ry="10" width="40" height="30" />
          <rect x="1379" y="235" rx="10" ry="10" width="40" height="30" />
          <rect x="1448" y="235" rx="10" ry="10" width="40" height="30" />
          <rect x="1516" y="235" rx="10" ry="10" width="40" height="30" />
          <rect x="1586" y="235" rx="10" ry="10" width="25" height="30" />

          <rect x="12" y="290" rx="4" ry="4" width="20" height="20" />
          <rect x="40" y="285" rx="10" ry="10" width="190" height="30" />
          <rect x="360" y="285" rx="10" ry="10" width="140" height="30" />
          <rect x="555" y="285" rx="10" ry="10" width="160" height="30" />
          <rect x="730" y="285" rx="10" ry="10" width="130" height="30" />
          <rect x="898" y="285" rx="10" ry="10" width="40" height="30" />
          <rect x="966" y="285" rx="10" ry="10" width="40" height="30" />
          <rect x="1035" y="285" rx="10" ry="10" width="40" height="30" />
          <rect x="1103" y="285" rx="10" ry="10" width="40" height="30" />
          <rect x="1171" y="285" rx="10" ry="10" width="40" height="30" />
          <rect x="1242" y="285" rx="10" ry="10" width="40" height="30" />
          <rect x="1310" y="285" rx="10" ry="10" width="40" height="30" />
          <rect x="1379" y="285" rx="10" ry="10" width="40" height="30" />
          <rect x="1448" y="285" rx="10" ry="10" width="40" height="30" />
          <rect x="1516" y="285" rx="10" ry="10" width="40" height="30" />
          <rect x="1586" y="285" rx="10" ry="10" width="25" height="30" />

          <rect x="12" y="340" rx="4" ry="4" width="20" height="20" />
          <rect x="40" y="335" rx="10" ry="10" width="300" height="30" />
          <rect x="400" y="335" rx="10" ry="10" width="100" height="30" />
          <rect x="605" y="335" rx="10" ry="10" width="110" height="30" />
          <rect x="760" y="335" rx="10" ry="10" width="100" height="30" />
          <rect x="898" y="335" rx="10" ry="10" width="40" height="30" />
          <rect x="966" y="335" rx="10" ry="10" width="40" height="30" />
          <rect x="1035" y="335" rx="10" ry="10" width="40" height="30" />
          <rect x="1103" y="335" rx="10" ry="10" width="40" height="30" />
          <rect x="1171" y="335" rx="10" ry="10" width="40" height="30" />
          <rect x="1242" y="335" rx="10" ry="10" width="40" height="30" />
          <rect x="1310" y="335" rx="10" ry="10" width="40" height="30" />
          <rect x="1379" y="335" rx="10" ry="10" width="40" height="30" />
          <rect x="1448" y="335" rx="10" ry="10" width="40" height="30" />
          <rect x="1516" y="335" rx="10" ry="10" width="40" height="30" />
          <rect x="1586" y="335" rx="10" ry="10" width="25" height="30" />

          <rect x="12" y="390" rx="4" ry="4" width="20" height="20" />
          <rect x="40" y="385" rx="10" ry="10" width="300" height="30" />
          <rect x="400" y="385" rx="10" ry="10" width="100" height="30" />
          <rect x="605" y="385" rx="10" ry="10" width="110" height="30" />
          <rect x="760" y="385" rx="10" ry="10" width="100" height="30" />
          <rect x="898" y="385" rx="10" ry="10" width="40" height="30" />
          <rect x="966" y="385" rx="10" ry="10" width="40" height="30" />
          <rect x="1035" y="385" rx="10" ry="10" width="40" height="30" />
          <rect x="1103" y="385" rx="10" ry="10" width="40" height="30" />
          <rect x="1171" y="385" rx="10" ry="10" width="40" height="30" />
          <rect x="1242" y="385" rx="10" ry="10" width="40" height="30" />
          <rect x="1310" y="385" rx="10" ry="10" width="40" height="30" />
          <rect x="1379" y="385" rx="10" ry="10" width="40" height="30" />
          <rect x="1448" y="385" rx="10" ry="10" width="40" height="30" />
          <rect x="1516" y="385" rx="10" ry="10" width="40" height="30" />
          <rect x="1586" y="385" rx="10" ry="10" width="25" height="30" />

          <rect x="12" y="440" rx="4" ry="4" width="20" height="20" />
          <rect x="40" y="435" rx="10" ry="10" width="300" height="30" />
          <rect x="400" y="435" rx="10" ry="10" width="100" height="30" />
          <rect x="605" y="435" rx="10" ry="10" width="110" height="30" />
          <rect x="760" y="435" rx="10" ry="10" width="100" height="30" />
          <rect x="898" y="435" rx="10" ry="10" width="40" height="30" />
          <rect x="966" y="435" rx="10" ry="10" width="40" height="30" />
          <rect x="1035" y="435" rx="10" ry="10" width="40" height="30" />
          <rect x="1103" y="435" rx="10" ry="10" width="40" height="30" />
          <rect x="1171" y="435" rx="10" ry="10" width="40" height="30" />
          <rect x="1242" y="435" rx="10" ry="10" width="40" height="30" />
          <rect x="1310" y="435" rx="10" ry="10" width="40" height="30" />
          <rect x="1379" y="435" rx="10" ry="10" width="40" height="30" />
          <rect x="1448" y="435" rx="10" ry="10" width="40" height="30" />
          <rect x="1516" y="435" rx="10" ry="10" width="40" height="30" />
          <rect x="1586" y="435" rx="10" ry="10" width="25" height="30" />

          <rect x="12" y="490" rx="4" ry="4" width="20" height="20" />
          <rect x="40" y="485" rx="10" ry="10" width="300" height="30" />
          <rect x="400" y="485" rx="10" ry="10" width="100" height="30" />
          <rect x="605" y="485" rx="10" ry="10" width="110" height="30" />
          <rect x="760" y="485" rx="10" ry="10" width="100" height="30" />
          <rect x="898" y="485" rx="10" ry="10" width="40" height="30" />
          <rect x="966" y="485" rx="10" ry="10" width="40" height="30" />
          <rect x="1035" y="485" rx="10" ry="10" width="40" height="30" />
          <rect x="1103" y="485" rx="10" ry="10" width="40" height="30" />
          <rect x="1171" y="485" rx="10" ry="10" width="40" height="30" />
          <rect x="1242" y="485" rx="10" ry="10" width="40" height="30" />
          <rect x="1310" y="485" rx="10" ry="10" width="40" height="30" />
          <rect x="1379" y="485" rx="10" ry="10" width="40" height="30" />
          <rect x="1448" y="485" rx="10" ry="10" width="40" height="30" />
          <rect x="1516" y="485" rx="10" ry="10" width="40" height="30" />
          <rect x="1586" y="485" rx="10" ry="10" width="25" height="30" />
        </ContentLoader>
      )}
      {/* <div className={styles.tableStatsWrap}>
        <table className={styles.tableStats}>
          <thead>
            <tr>
              <th>1.09</th>
              <th>2.09</th>
              <th>3.09</th>
              <th>4.09</th>
              <th>5.09</th>
              <th>6.09</th>
              <th>7.09</th>
              <th>8.09</th>
              <th>9.09</th>
              <th>10.09</th>
              <th>11.09</th>
              <th>12.09</th>
              <th>13.09</th>
              <th>14.09</th>
              <th>15.09</th>
            </tr>
          </thead>
          <tbody>
            {isUniqueKeywords
              ? filteredData.map((row, idx) => (
                  <tr key={idx}>
                    {[...row.stats].reverse().map((stat, idx) => (
                      <td
                        key={idx}
                        style={{ backgroundColor: selectColor(stat.position) }}
                      >
                        {stat.position}
                      </td>
                    ))}
                  </tr>
                ))
              : filteredData.map((row, idx) => (
                  row.keywords.map((keyword, keyIndex) => (
                    <tr key={idx}>
                    {[...row.stats].reverse().map((stat, idx) => (
                      <td
                        key={idx}
                        style={{ backgroundColor: selectColor(stat.position) }}
                      >
                        {stat.position}
                      </td>
                    ))}
                  </tr>
                  ))
                ))}
          </tbody>
        </table>
      </div> */}
    </Content>
  );
}
