import React from 'react'
import styles from './LastNews.module.scss'

export default function LastNews() {
  return (
    <div className={styles.block}>
      <div className={styles.newsBlock}>
        <div className={styles.news}>
          <div className={styles.title}>
            Дайджест #3
          </div>
          <div className={styles.data}>
            <div className={styles.category}>
              <span className={styles.categoryName}>Новости WB</span>
            </div>
            <div className={styles.date}>26/08/2022</div>
          </div>
          <div className={styles.text}>
            За эту неделю Wildberries ввёл новые ограничения для поставщиков 😱
          </div>
        </div>
      </div>

      <div className={styles.newsBlock}>
        <div className={styles.news}>
          <div className={styles.title}>
            Обновление v1.0.2
          </div>
          <div className={styles.data}>
            <div className={styles.category}>
              <span className={styles.categoryName} style={{'background':'#761B31'}}>Администрации</span>
            </div>
            <div className={styles.date}>24/08/2022</div>
          </div>
          <div className={styles.text}>
            Как всегда радуем тебя новым функционалом каждую сука неделю 🤣
          </div>
        </div>
      </div>
    </div>
  )
}
