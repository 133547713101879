import React from 'react'
import AdsCampaignsFeed from '../components/blocks/ads-campaigns-feed/AdsCampaignsFeed'
import AdsCampaignsGraph from '../components/blocks/ads-campaigns-graph/AdsCampaignsGraph'
import GraphOrders from '../components/blocks/graph-orders/GraphOrders'
import LastNews from '../components/blocks/last-news/LastNews'
import TodayOrdersFeed from '../components/blocks/today-orders-feed/TodayOrdersFeed'
import TodayOrders from '../components/blocks/today-orders/TodayOrders'
import Content from '../components/layout/page/Content'
import PageTitle from '../components/ui/page-title/PageTitle'

export default function Home() {
  return (
    <>
      <PageTitle title='Рабочий стол'/>
      <Content>
          <TodayOrders/>
          <GraphOrders/>
          <LastNews/>
      </Content>
      <TodayOrdersFeed/>
      <Content>
        <AdsCampaignsFeed/>
        <AdsCampaignsGraph/>
      </Content>
    </>
  )
}
