import React from "react";
import Logo from "./logo/Logo";
import Navigation from "./navigation/Navigation";
import styles from "./Sidebar.module.scss";

export default function Sidebar(props) {
  return (
    <>
    <div className={styles.sidebar}>
      <div className={styles.inner}>
        <Logo/>
        <Navigation>
          {props.children}
        </Navigation>
      </div>
    </div>
    </>
  )
}