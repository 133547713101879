import React from 'react'
import styles from './TodayOrders.module.scss'

export default function TodayOrders() {
  return (
    <div className={styles.todayorders}>
      <div className={styles.title}>
        Заказы за сегодня
      </div>
      <div className={styles.stats}>
        <div className={styles.subtitle}>
          Количество
        </div>
        <div className={styles.counter}>
          <div className={styles.count}>321</div>
          <div className={styles.measure}>шт.</div>
        </div>
        <div className={styles.beforeWrap}>
          <span className={`${styles.before} ${styles.green}`}>
            280 шт. за вчера
          </span>
        </div>
      </div>
      <div className={styles.stats}>
        <div className={styles.subtitle}>
          Сумма
        </div>
        <div className={styles.counter}>
          <div className={styles.count}>341 680</div>
          <div className={styles.measure}>руб.</div>
        </div>
        <div className={styles.beforeWrap}>
          <span className={`${styles.before} ${styles.red}`}>
            308 660 руб. за вчера
          </span>
        </div>
      </div>
      <a href="/" className={styles.link}>
        Подробная статистика →
      </a>
    </div>
  )
}
