import Constants from 'wb-private-api/src/Constants'
import format from 'string-format'

format.extend(String.prototype, {})

export const randomArrayGenerator = (count) => {
    let arr = [];
    for (let i = 0; i < count; i++) {
      arr.push(getRandomInt(1, 350));
    }
    return arr;
  };
  
export const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  };

export const productCardPoster = (productId, imageType = 'SMALL', order = 1) => {
    const archive = parseInt(productId / 10000, 10);
    const URL = Constants.URLS.IMAGES[imageType];
    return URL.format(archive, productId, '', order);
  };