import React from 'react'
import styles from './TodayOrdersFeed.module.scss'

export default function TodayOrderCardEmpty() {
  return (
    <div className={styles.blockEmpty}>
      <div className={styles.emptyText}>
        Здесь появится
        будущий заказ
      </div>
    </div>
  )
}
