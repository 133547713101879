import React from 'react'
import styles from './Content.module.scss'

export default function Content(props) {
  const mainClasses = props.className ? [styles.content, props.className] : [styles.content]
  return (
    <div className={mainClasses.join(' ')} style={props.style}>
      {props.children}
    </div>
  )
}
