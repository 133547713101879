import { RPMGraphOptions } from './RPMGraph.options'
import React, { useState } from 'react'
import Chart from 'react-apexcharts'
import { useEffect } from 'react'
import { faLinkSlash } from '@fortawesome/free-solid-svg-icons'

export default function RPMGraph({graphData}) {
  console.log(graphData)
  const [data, setData] = useState({series:[], ...RPMGraphOptions()})

  useEffect(() => {
    const colors = graphData.map(value => value.color)
    setData({
      series: graphData.map(value => {
        return {
          name: value.keywords[0].keyword,
          data: value.stats.map(v => {
            return {
              x: v.date,
              y: v.position,
            }
          })
        }
      }),
      ...RPMGraphOptions(colors)
    })
  }, [graphData])

  return (
    <Chart options={data.options} series={data.series} type="area" height="100%"/>
  )
}
