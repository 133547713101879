import { AdsCampaignsFeedGraphOptions } from './AdsCampaignsFeedGraphOptions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink } from '@manysale/pro-light-svg-icons'
import styles from './AdsCampaignsFeed.module.scss'
import React, { useState } from 'react'
import Chart from 'react-apexcharts'

export default function AdsCampaignsFeedCard(props) {
  const [graphData, setGraphData] = useState({
    series: [{
      name: 'series1',
      data: [5, 12, 45, 22, 34, 39, 60],
    }],
    ...AdsCampaignsFeedGraphOptions
  })

  return (
    <div className={styles.block}>
      <div className={styles.section}>
        <div className={`${styles.cardLine} ${styles.sb}`}>
          <div className={styles.status}>
            <div className={styles.circle} style={{background: props.statusColor}}></div>
            <div className={styles.statusName}>
              {props.status}
            </div>
          </div>
          <div className={styles.sku}>
            <div className={styles.externalLink}>
              <FontAwesomeIcon icon={faExternalLink} className={styles.externalIcon}/>60059650
            </div>
          </div>
        </div>
        <div className={`${styles.cardLine} ${styles.sb}`}>
          <div className={styles.campaignName}>
            Cuplee Новый (A/B)
          </div>
          <div className={styles.category}>
            Поиск
          </div>
        </div>
        <div className={styles.cardLine}>
          <div className={styles.image}>
            <img className={styles.imageBlur} src="https://images.wbstatic.net/c246x328/new/60050000/60059650-1.jpg"></img>
            <img className={styles.imageMain} src="https://images.wbstatic.net/c246x328/new/60050000/60059650-1.jpg"></img>
          </div>
          <div className={styles.statsContainer}>
            <div className={styles.row}>
              <div className={styles.title}>Позиция</div>
              <div className={styles.data}>4 / 20</div>
            </div>
            <div className={styles.row}>
              <div className={styles.title}>Бюджет</div>
              <div className={styles.data}>11 680</div>
            </div>
            <div className={styles.row}>
              <div className={styles.title}>Ставка</div>
              <div className={styles.data}>1 620</div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.section} ${styles.graphSection}`}>
      <Chart options={graphData.options} series={graphData.series} type="area" height="100%"/>
      </div>
      <div className={`${styles.section} ${styles.matrixSection}`}>
        <div className={styles.matrixHeader}>
          Матрица ставок конкурентов
        </div>
        <div className={styles.matrix}>
          <div className={styles.matrixBlock}>
            <div className={styles.matrixCounter}>1</div>
            <div className={styles.matrixData}>1992</div>
          </div>
          <div className={styles.matrixBlock}>
            <div className={styles.matrixCounter}>2</div>
            <div className={styles.matrixData}>1992</div>
          </div>
          <div className={`${styles.matrixBlock} ${styles.matrixActive}`}>
            <div className={styles.matrixCounter}>3</div>
            <div className={styles.matrixData}>1992</div>
          </div>
          <div className={styles.matrixBlock}>
            <div className={styles.matrixCounter}>4</div>
            <div className={styles.matrixData}>1992</div>
          </div>
          <div className={styles.matrixBlock}>
            <div className={styles.matrixCounter}>5</div>
            <div className={styles.matrixData}>1992</div>
          </div>
          <div className={styles.matrixBlock}>
            <div className={styles.matrixCounter}>6</div>
            <div className={styles.matrixData}>1992</div>
          </div>
          <div className={styles.matrixBlock}>
            <div className={styles.matrixCounter}>7</div>
            <div className={styles.matrixData}>1992</div>
          </div>
          <div className={styles.matrixBlock}>
            <div className={styles.matrixCounter}>8</div>
            <div className={styles.matrixData}>1992</div>
          </div>
          <div className={styles.matrixBlock}>
            <div className={styles.matrixCounter}>9</div>
            <div className={styles.matrixData}>1992</div>
          </div>
          <div className={styles.matrixBlock}>
            <div className={styles.matrixCounter}>10</div>
            <div className={styles.matrixData}>1992</div>
          </div>
          <div className={styles.matrixBlock}>
            <div className={styles.matrixCounter}>11</div>
            <div className={styles.matrixData}>1992</div>
          </div>
          <div className={styles.matrixBlock}>
            <div className={styles.matrixCounter}>12</div>
            <div className={styles.matrixData}>1992</div>
          </div>
        </div>
      </div>
    </div>
  )
}
