import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import TodayOrderCard from './TodayOrderCard'
import TodayOrderCardEmpty from './TodayOrderCardEmpty'
import styles from './TodayOrdersFeed.module.scss'

export default function TodayOrdersFeed() {
  return (
    <>
      <div className={styles.header}>
        <h3 className={styles.headerTitle}>
          Последние заказы
        </h3>
        <div className={styles.right}>
          <div className={styles.links}>
            Посмотреть все заказы →
          </div>
        </div>
      </div>
      <div className={styles.feed}>
          <TodayOrderCard/>
          <TodayOrderCard/>
          <TodayOrderCard/>
          <TodayOrderCardEmpty/>
          <TodayOrderCardEmpty/>
      </div>
    </>
  )
}
