import React from "react";
import { Route, Routes, Navigate, Outlet } from "react-router";
import Layout from "./components/layout/Layout";
import "react-toastify/dist/ReactToastify.css";
import "./global.scss";
import Home from "./routes/Home";
import Login from "./routes/Login";
import Register from "./routes/Register";
import { useSelector, useDispatch } from "react-redux";
import { checkToken } from "./store/actions/AuthActions";
import { useEffect } from "react";
import AuthRoute from "./routes/AuthRoute";
import RPM from "./routes/RPM";
import RPMSearch from "./components/special/rpm-page/RPMSearch";

export default function App() {
  const dispatch = useDispatch();
  const { isAuth } = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (
      localStorage.getItem("refresh_token") &&
      localStorage.getItem("access_token") &&
      !isAuth
    ) {
      dispatch(checkToken());
    }
  }, []);

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route
          path="/"
          element={
            <AuthRoute>
              <Home />
            </AuthRoute>
          }
        />
        <Route
          path="/rpm"
          element={
            <AuthRoute>
              <RPMSearch />
            </AuthRoute>
          }
        />
        <Route
          path="/rpm/:query"
          element={
            <AuthRoute>
              <RPMSearch />
            </AuthRoute>
          }
          onLeave={() => {
            console.log("EXIT");
          }}
        />
        <Route
          path="/rpm/product/:productId"
          element={
            <AuthRoute>
              <RPM />
            </AuthRoute>
          }
        />
      </Route>
      <Route
        path="/login"
        element={
          <AuthRoute not>
            <Login />
          </AuthRoute>
        }
      />
      <Route
        path="/Register"
        element={
          <AuthRoute not>
            <Register />
          </AuthRoute>
        }
      />
    </Routes>
  );
}
