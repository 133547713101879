const hexToRgbA = (hex, alpha) => {
  var c;
  if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
      c= hex.substring(1).split('');
      if(c.length== 3){
          c= [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c= '0x'+c.join('');
      return `rgba(${[(c>>16)&255, (c>>8)&255, c&255].join(',')},${alpha})`;
  }
  throw new Error(`Bad Hex ${hex}`);
}

export const RPMGraphOptions = (colors = []) => {
  return {
    options: {
      theme: {mode: 'dark'},
      colors: colors,
      fill: {
        colors: colors.map(color => hexToRgbA(color, 0.12)),
        gradient: {
          shadeIntensity: 0
        }
      },
      chart: {
        background: '#161828',
        height: '100%',
        width: '100%',
        type: 'area',
        toolbar: {
          show: false,
        },
        parentHeightOffset: 0,
        sparkline: {
          enabled: true
        },
        dropShadow: {
          enabled: true,
          enabledOnSeries: [0, 1],
          blur: 20,
          opacity: 0.2,
          color: colors.map(color => hexToRgbA(color, 0.12)),
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        type: 'datetime',
        categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm'
        },
      },
      legend: {
        show: false,
      },
      yaxis: {
        show: false,
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      xaxis: {
        labels: {
          show: false
        },
        axisTicks: {
          show: false
        },
      },
      grid: {
        padding: {
          left: 0,
          right: 0
        },
        yaxis: {
          lines: {
            show: false,
          }
        }
      },
      stroke: {
        width: 2,
        dashArray: [4,0]
      },
      markers: {
        size: 0,
        strokeWidth: 1,
        strokeOpacity: .5,
        hover: {
          size: 5,
        }
      },
      tooltip: {
        enabled: true,
        enabledOnSeries: undefined,
        shared: true,
        followCursor: true,
        intersect: false,
        inverseOrder: false,
        custom: undefined,
        fillSeriesColor: false,
        theme: false,
        style: {
          fontSize: '12px',
          fontFamily: undefined
        },
        onDatasetHover: {
            highlightDataSeries: true,
        },
        x: {
            show: true,
            format: 'dd MMM',
            formatter: undefined,
        },
        y: {
            formatter: undefined,
            title: {
                formatter: (seriesName) => seriesName,
            },
        },
        z: {
            formatter: undefined,
            title: 'Size: '
        },
        marker: {
            show: true,
        },
        items: {
           display: 'flex',
        },
        fixed: {
            enabled: false,
            position: 'topRight',
            offsetX: 0,
            offsetY: 0,
        },
    }
    }
  }
}