import { GraphOrdersOptions } from './GraphOrdersOptions'
import React, { useState } from 'react'
import Chart from 'react-apexcharts'
import styles from './GraphOrders.module.scss'

export default function GraphOrders() {
  const [graphData, setGraphData] = useState({
    series: [{
      name: 'series1',
      data: [11, 32, 45, 32, 34, 52, 41],
    }, {
      name: 'series2',
      data: [31, 40, 28, 51, 42, 109, 100]
    }],
    ...GraphOrdersOptions
  })

  return (
    <div className={styles.block}>
      <div className={styles.title}>
        Статистика заказов
      </div>
      <div className={styles.legend}>
        <div className={styles.row}>
          <div className={styles.circle}></div>
          <div className={styles.legendTitle}>Текущая неделя</div>
        </div>
        <div className={styles.row}>
          <div className={`${styles.circle} ${styles.red}`}></div>
          <div className={styles.legendTitle}>Прошлая неделя</div>
        </div>
      </div>
      <Chart options={graphData.options} series={graphData.series} type="area" height="100%"/>
    </div>
  )
}
