import { RPMWideGraphOptions } from './RPMWideGraph.options'
import React, { useState } from 'react'
import Chart from 'react-apexcharts'
import { useEffect } from 'react'

export default function RPMWideGraph({graphData}) {
  const [data, setData] = useState({series:[], ...RPMWideGraphOptions()})

  useEffect(() => {
    const colors = graphData.map(value => value.color)
    setData({
      series: graphData.map(value => {
        return {
          name: value.keywords[0].keyword,
          data: value.stats.map(v => {
            return {
              x: v.date,
              y: v.position,
            }
          })
        }
      }),
      ...RPMWideGraphOptions(colors)
    })
  }, [graphData])

  return (
    <Chart options={data.options} series={data.series} type="area" height="100%"/>
  )
}
