import { AdsCampaignsGraphOptions } from './AdsCampaignsGraphOptions'
import React, { useState } from 'react'
import Chart from 'react-apexcharts'
import styles from './AdsCampaignsGraph.module.scss'

export default function AdsCampaignsGraph() {
  const [graphData, setGraphData] = useState({
    series: [{
      name: 'series1',
      data: [11, 32, 45, 32, 34, 52, 41],
    }, {
      name: 'series2',
      data: [31, 40, 28, 51, 42, 109, 100]
    }],
    ...AdsCampaignsGraphOptions
  })

  return (
    <div className={styles.adsGraphBlock}>
    <div className={styles.header}>
      <h3 className={styles.headerTitle}>
        Расходы на рекламу
      </h3>
      <div className={styles.right}>
        <div className={styles.links}>
          Все рекламные кампании →
        </div>
      </div>
    </div>

      <div className={styles.block}>
        <div className={styles.title}>
          Статистика по расходам
        </div>
        <div className={styles.legend}>
          <div className={styles.row}>
            <div className={styles.circle}></div>
            <div className={styles.legendTitle}>Текущая неделя</div>
          </div>
          <div className={styles.row}>
            <div className={`${styles.circle} ${styles.red}`}></div>
            <div className={styles.legendTitle}>Прошлая неделя</div>
          </div>
        </div>
        <Chart options={graphData.options} series={graphData.series} type="area" height="100%"/>
      </div>
    </div>
  )
}
