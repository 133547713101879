import { createSlice } from "@reduxjs/toolkit";
import { getKeywords } from "../actions/RpmActions";

const initialState = {
  keywords: [],
  isPending: false,
  error: '',
}

export const RpmKeywordsSlice = createSlice({
  name: 'rpmKeywords',
  initialState,
  reducers: {},
  extraReducers: (builder) =>  {
    builder
      .addCase(getKeywords.pending, (state) => {
        state.isPending = true
      })
      .addCase(getKeywords.fulfilled, (state, action) => {
        state.isPending = false
        state.keywords = action.payload
      })
      .addCase(getKeywords.rejected, (state, action) => {
        state.isPending = false;
        state.error = action.payload.error
        state.keywords = {}
      })
  }
})

export default RpmKeywordsSlice.reducer