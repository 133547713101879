import { createSlice } from "@reduxjs/toolkit";
import { getHints } from "../actions/RpmActions";

const initialState = {
  hints: [],
  isPending: false,
  error: '',
}

export const RpmHintsSlice = createSlice({
  name: 'rpmHints',
  initialState,
  reducers: {},
  extraReducers: (builder) =>  {
    builder
      .addCase(getHints.pending, (state) => {
        state.isPending = true
      })
      .addCase(getHints.fulfilled, (state, action) => {
        state.isPending = false
        state.hints = action.payload
      })
      .addCase(getHints.rejected, (state, action) => {
        state.isPending = false;
        state.error = action.payload.error
        state.hints = []
      })
  }
})

export default RpmHintsSlice.reducer