const GraphOrdersOptions = {
  options: {
    colors: ['#B13510','#60A6FF'],
    fill: {
      colors: ['rgba(177, 53, 16, 0.12)', 'rgba(96, 166, 255, 0.12)'],
      gradient: {
        shadeIntensity: 0
      }
    },
    chart: {
      height: '100%',
      width: '100%',
      type: 'area',
      toolbar: {
        show: false,
      },
      parentHeightOffset: 0,
      sparkline: {
        enabled: true
      },
      dropShadow: {
        enabled: true,
        enabledOnSeries: [0, 1],
        blur: 20,
        opacity: 0.2,
        color: ['#B13510','#60A6FF'],
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      type: 'datetime',
      categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm'
      },
    },
    legend: {
      show: false,
    },
    yaxis: {
      show: false,
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    xaxis: {
      labels: {
        show: false
      },
      axisTicks: {
        show: false
      },
    },
    grid: {
      padding: {
        left: 0,
        right: 0
      },
      yaxis: {
        lines: {
          show: false,
        }
      }
    },
    stroke: {
      width: 2,
      dashArray: [4,0]
    },
    markers: {
      size: 0,
      strokeWidth: 1,
      strokeOpacity: .5,
      hover: {
        size: 5,
      }
    },
    tooltip: {
      enabled: false,
    }
  }
}

export { GraphOrdersOptions }