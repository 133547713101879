import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styles from './Item.module.scss';
import { NavLink } from 'react-router-dom'

export default function Item(props) {

  const activeClassHandle = ({ isActive }) => {
    return isActive ? styles.active_link : ''
  }

  return (
    <NavLink
      to={props.to}
      onClick={(e) => { props.disabled && e.preventDefault() }}
      data-disabled={props.disabled}
      className={activeClassHandle}
    >
      <div className={styles.item} key={props.title}>
        <div className={styles.icon}>
          <FontAwesomeIcon icon={props.icon}/>
        </div>
        <div className={styles.title}>
          {props.title}
        </div>
      </div>
    </NavLink>
  )
}
