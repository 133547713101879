import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { InfinitySpin } from 'react-loader-spinner'
import styles from '../register-page/RegisterPage.module.scss'
import decorations from '../../../assets/styles/decorations.module.scss'
import logo from '../../../assets/img/logo.png'
import Version from '../../layout/version/Version'
import { useSelector, useDispatch } from 'react-redux'
import { auth } from '../../../store/actions/AuthActions'
import { toast } from 'react-toastify'

export default function LoginPage() {
  const dispatch = useDispatch();
  const {user, isAuth, isPending, error} = useSelector(state => state.authReducer)
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const [isValidLogin, setIsValidLogin] = useState(false)
  const [isValidPassword, setIsValidPassword] = useState(false)
  const [isReady, setIsReady] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    if(isAuth){
      toast.success('Авторизация прошла успешно', {
        autoClose: 1000,
        onClose: () => {
          navigate('/')
        }
      })
      localStorage.setItem('access_token', user.access_token)
      localStorage.setItem('refresh_token', user.refresh_token)
    }
  }, [isAuth, navigate, user])

  useEffect(() => {
    setIsValidLogin(login?.length >= 4)
  }, [login])

  useEffect(() => {
    setIsValidPassword(password?.length >= 8)
  }, [password])

  useEffect(() => {
    return setIsReady(isValidLogin && isValidPassword)
  }, [isValidLogin, isValidPassword])

  const handleSubmit = (event) => {
    event.preventDefault()

    const actionResult = dispatch(auth({login, password}))
    actionResult.then((action) => {
      if(action.payload.error !== '')
        return toast.error(action.payload.error);
    })
  }

  return (
    <div className={styles.page}>
      <div className={styles.logo}>
        <img src={logo} alt="Logo"></img>
      </div>
      <div className={decorations.circleTop}/>
      <form
        className={isPending ? `${styles.form} ${styles.formPending}` : isReady ? `${styles.form} ${styles.formReady}` : styles.form}
        onSubmit={handleSubmit}
      >
        <input
          type="text"
          placeholder='Логин'
          value={login}
          className={ login ? isValidLogin ? styles.valid : null : null }
          onChange={(e) => setLogin(e.target.value)}
          disabled={isPending}
        />
        <input
        type="password"
        placeholder='Пароль'
        value={password}
        className={ password ? isValidPassword ? styles.valid : null : null }
        onChange={(e) => setPassword(e.target.value)}
        disabled={isPending}
        />
        <button
          type="submit"
          className={isPending ? styles.pending : null}
          disabled={!isReady || isPending}
        >
        {!isPending && <>Войти</>}
        {
          isPending &&
          <div className={styles.loader}>
            <InfinitySpin
              width="100"
              color="#254C7D"
            />
          </div>
        }
        </button>

        <div className={styles.regText}>
          Нет аккаунта?
          <Link to="/register">
            Создать аккаунт
          </Link>
        </div>
      </form>
      <Version/>
    </div>
  )
}
