import { createSlice } from "@reduxjs/toolkit";
import { clearProducts, getProductsByQuery } from "../actions/RpmActions";

const initialState = {
  products: [],
  keywordStats: {},
  isPending: false,
  error: '',
}

export const RpmSearchProductsSlice = createSlice({
  name: 'rpmSearchProducts',
  initialState,
  reducers: {},
  extraReducers: (builder) =>  {
    builder
      .addCase(getProductsByQuery.pending, (state) => {
        state.isPending = true
      })
      .addCase(getProductsByQuery.fulfilled, (state, action) => {
        state.isPending = false
        state.products = action.payload.products
        state.keywordStats = action.payload.keywordStats
      })
      .addCase(getProductsByQuery.rejected, (state, action) => {
        state.isPending = false;
        state.error = action.payload.error
        state.keywordStats = {}
        state.products = []
      })
    
    builder
      .addCase(clearProducts.pending, (state) => {
        state.isPending = true;
        state.error = ''
        state.keywordStats = {}
        state.products = []
      })
      .addCase(clearProducts.fulfilled, (state) => {
        state.isPending = false;
        state.error = ''
        state.keywordStats = {}
        state.products = []
      })
  }
})

export default RpmSearchProductsSlice.reducer