import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faExternalLink} from '@manysale/pro-light-svg-icons'
import styles from './TodayOrdersFeed.module.scss'
import React from 'react'

export default function TodayOrderCard() {
  return (
    <div className={styles.block}>
      <div className={styles.image}>
        <img className={styles.imageBlur} src="https://images.wbstatic.net/c246x328/new/60050000/60059650-1.jpg"></img>
        <img className={styles.imageMain} src="https://images.wbstatic.net/c246x328/new/60050000/60059650-1.jpg"></img>
      </div>
      <div className={styles.statsContainer}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.title}>Сумма</div>
            <div className={styles.data}>990</div>
          </div>
          <div className={styles.col}>
            <div className={styles.title}>Регион</div>
            <div className={`${styles.data} ${styles.region}`}>Москва</div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.title}>Время</div>
            <div className={styles.data}>23:55</div>
          </div>
          <div className={styles.col}>
            <div className={styles.title}>Артикул</div>
            <div className={styles.data}>
              <div className={styles.externalLink}>
                <FontAwesomeIcon icon={faExternalLink} className={styles.externalIcon}/>60059650
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
