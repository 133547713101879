import AuthService from "../../services/AuthService";
import { createAsyncThunk } from '@reduxjs/toolkit'

export const auth = createAsyncThunk(
  'auth',
  async (data, thunkAPI) => {
    const {login, password} = data
    try {
      const response = await AuthService.auth(login, password)
      if(response.data?.error){
        return thunkAPI.rejectWithValue(response.data)
      }
      return response.data
    } catch(e) {
      console.log(e.message)
    }
  }
)


export const register = createAsyncThunk(
  'register',
  async (data, thunkAPI) => {
    const {login, email, password} = data
    try {
      const response = await AuthService.register(login, email.toLowerCase(), password)
      console.log(response)
      if(response.data?.error){
        return thunkAPI.rejectWithValue(response.data)
      }
      return response.data
    } catch(e) {
      console.log(e.message)
    }
  }
)

export const logout = createAsyncThunk(
  'register',
  async (_, thunkAPI) => {
    try {
      const response = await AuthService.logout()
      if(response.data?.error){
        return thunkAPI.rejectWithValue(response.data)
      }
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')
      return response.data
    } catch(e) {
      console.log(e.message)
    }
  }
)

export const checkToken = createAsyncThunk(
  'checkToken',
  async(_, thunkAPI) => {
    try {
      const response = await AuthService.checkToken()
      if(response.data?.error){
        localStorage.removeItem('access_token')
        return thunkAPI.rejectWithValue(response.data)
      }
      return response.data
    } catch(e) {
      console.log(e.message)
    }
  }
)