import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@manysale/pro-light-svg-icons'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { logout } from '../../../store/actions/AuthActions';
import styles from './ButtonAccountTop.module.scss'

export default function ButtonAccountTop(props) {
  const dispatch = useDispatch();
  const {user, isAuth, isPending, error} = useSelector(state => state.authReducer)

  const onClickLogout = () => {
    const actionResult = dispatch(logout())
    actionResult.then((action) => {
      if(action.payload.error !== '') {
        return toast.error(action.payload.error);
      }
      console.log(action.payload)
    })
  }
  return (
    <div className={styles.account_top} onClick={onClickLogout}>
      <div className={styles.img}>
        <span className={styles.avatar}>
          <span className={styles.avatar_img}>
            <img src="https://sun9-36.userapi.com/s/v1/if2/SjiBQYx22GHW_OMi6bl7oJQGjKMF8mMi3p_YY4yhT0M8nVI1j56So2y_tlNZapZ211hCsalneamaU_dAoAylw9Rj.jpg?size=100x100&amp;quality=96&amp;crop=42,34,322,322&amp;ava=1" alt="avatar"/>
          </span>
        </span>
      </div>
      <div className={styles.dropdown}>
        <FontAwesomeIcon icon={faUser}/>
      </div>
    </div>
  )
}
